<template>
  <form @submit.prevent="saveOrUpdateCity">
    <c-422-errors :errors="errors" />
    <div class="mb-3">
      <label for="mlg" class="form-label">Estado:</label>
      <input :value="state.name" class="form-control" id="mlg" disabled />
    </div>
    <div class="mb-3">
      <label for="city" class="form-label">Municipio:</label>
      <input v-model="city.name" class="form-control" id="city" required />
    </div>
    <c-primary-button class="w-100" :disabled="loading">
      Guardar
    </c-primary-button>
  </form>
</template>
<script>
import C422Errors from "@/components/C422Errors";
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import { find, createCity } from "@/services/states";
import { find as findCity, update } from "@/services/cities";
import { showNotification } from "@/services/notification";
export default {
  components: {
    C422Errors,
    CPrimaryButton,
  },
  data() {
    return {
      cityId: this.$route.params.cityId,
      loading: false,
      errors: {},
      stateId: this.$route.params.id,
      state: { name: "" },
      city: { version: "" },
    };
  },
  mounted() {
    this.getState();
    this.getCity();
  },
  methods: {
    getState() {
      find(this.stateId).then((response) => {
        this.state = response.data;
      });
    },
    getCity() {
      if (!this.cityId) {
        return;
      }
      findCity(this.cityId).then((response) => {
        this.city = response.data;
      });
    },
    saveOrUpdateCity() {
      this.loading = true;
      this.cityId ? this.updateCity() : this.saveCity();
    },
    saveCity() {
      createCity(this.stateId, this.city)
        .then(() => {
          this.loading = false;
          showNotification(201);
          this.$router.push(
            `/admin/configuraciones/estados/${this.stateId}/municipios`
          );
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    updateCity() {
      update(this.cityId, this.city).then(() => {
        showNotification(201);
        this.$router.push(
          `/admin/configuraciones/estados/${this.stateId}/municipios`
        );
      });
    },
  },
};
</script>
