import axios from "@/plugins/Axios";

const model = "cities";

function all() {
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}`);
}

function find(id) {
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}/${id}`);
}

function remove(id) {
  return axios.delete(`${process.env.VUE_APP_API_URL}/${model}/${id}`);
}

function update(id, data) {
  return axios.put(`${process.env.VUE_APP_API_URL}/${model}/${id}`, data);
}

export { all, find, remove, update };
